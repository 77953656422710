import { App } from '../components/App'
import { PageContext }from '../contexts/pageContext'
import Head from 'next/head';
import Script from 'next/script';
import { getConfig } from '../contexts/configs/index'
function HomePage() {

const shopDomain = process.env.NEXT_PUBLIC_API_URL;

return (
    <>
    <Head>
        <title> Claros </title>
        <link rel="icon" href="./giftem-logo.png" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
    </Head>
    <PageContext.Provider value={getConfig("giftem")}>
        <App />
    </PageContext.Provider>
    </>
  )
}
export default HomePage